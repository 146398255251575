@import '../../../scss/theme-bootstrap';

.offer-promo-mobile-v1 {
  text-align: center;
  height: 40px;
  cursor: pointer;
}

.offer-promo-mobile-v1 .content {
  height: 100%;
  *position: absolute;
  top: 50%;
  display: table-cell;
  vertical-align: bottom;
  width: auto;
  display: inline;
  font-size: 100%;
}

.offer-promo-carousel-v1 .cycle-carousel-wrap {
  #{$rdirection}: 0;
  bottom: 0;
  width: 100%;
}

.offer-promo-mobile-v1 .headline,
.offer-promo-mobile-v1 .cta {
  white-space: nowrap;
  display: inline-table;
  padding: 11px 0 0;
}

.offer-promo-mobile-v1 .headline {
  font-size: 90%;
  font-family: $base-bold-font-family;
  -webkit-font-smoothing: antialiased;
  color: $color-cl-green-2;
  text-transform: uppercase;
  margin-#{$rdirection}: 8px;
}

.offer-promo-mobile-v1 .cta,
.offer-promo-mobile-v1 .cta a {
  color: #000;
  font-size: 15px;
  padding-#{$rdirection}: 4px;
}

.offer-promo-mobile-v1 .cta span,
.offer-promo-mobile-v1 .cta span a {
  color: inherit;
}

@media screen and (max-width: 640px) {
  #header .top-right .block.block-template-offer-promo-mobile-v1 {
    display: none;
  }
}

@media screen and (min-width: 400px) and (max-width: 640px) {
  .offer-promo-mobile-v1,
  .offer-promo-mobile-v1 .headline,
  .offer-promo-mobile-v1 .cta {
    font-size: 20px;
  }
  .offer-promo-mobile-v1 .cta a {
    font-size: 20px;
  }
}
